import * as React from "react";

import SEO from "../components/seo";

export default function AboutPage() {
  const sitesWorkedOn = [
    {
      link: "https://www.ft.com",
      image: "ft.jpg",
      name: "FT.com",
      title: "The Financial Times",
      details: "Node, Express, AWS, Lambdas, Javascript, Sass",
    },
    {
      link: "https://www.appearhere.co.uk/",
      image: "appearhere.jpg",
      name: "appear[here]",
      details: "HTML5, CSS3, SASS, React JS, jQuery, Javascript",
    },
    {
      link: "https://www.lonelyplanet.com/",
      image: "lonelyplanet.jpg",
      name: "Lonely Planet",
      details:
        "HTML5, CSS3, SASS, jQuery, Coffeescript & Javascript, GruntJS, RequireJS",
    },
    {
      link: "http://regexcavate.github.io/regexcavate/",
      image: "regexcavate.jpg",
      name: "Regexcavate",
      details:
        "100% open source, HTML5, CSS3, Compass (SCSS syntax), jQuery, GruntJS (including custom written task)",
    },
    {
      link: "https://www.biscuiteers.com/",
      image: "biscuiteers.jpg",
      name: "Biscuiteers",
      details:
        "Wordpress, Magento, HTML5 elements &amp; data attributes, CSS3, jQuery",
    },
    {
      link: "http://www.tyrrellscrisps.co.uk/",
      image: "tyrrells.jpg",
      name: "Tyrrells",
      details: "Wordpress, HTML5 elements &amp; data attributes, CSS3, jQuery",
    },
    {
      link: "https://www.roddas.co.uk/",
      image: "roddas.jpg",
      name: "Roddas",
      details: "WordPress, HTML5 elements, CSS3, jQuery",
    },
    {
      link: "https://www.belvoirfarm.co.uk/",
      image: "belvoir.jpg",
      name: "Belvoir",
      details: "WordPress, Magento, HTML, CSS, jQuery",
    },
    {
      link: "https://www.bighams.com/",
      image: "bighams.jpg",
      name: "Bighams",
      details: "WordPress, HTML, CSS, jQuery",
    },
    {
      link: "https://www.sofa.com/",
      image: "sofa.jpg",
      name: "Sofa.com",
      details: "WordPress, Magento, CSS, jQuery",
    },
  ];

  return (
    <>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title="My Work" />
      <h1>My Work</h1>

      <p>This is some of the stuff I've worked on.</p>

      <ul className="no-md">
        {sitesWorkedOn.map((site) => (
          <li key={site.name}>
            <a href={site.link}>
              <img src={`/images/work/${site.image}`} alt={site.name} />
            </a>
            <h2>
              <a href={site.link} title={site.title}>
                {site.name}
              </a>
            </h2>
            <p>{site.details}</p>
          </li>
        ))}
      </ul>
    </>
  );
}
